<template>
  <div class="chapterDetail">
    <div v-show="!isCard">
      <examinationHeader ref="examinationHeader" v-if="paperAnswerTime" :paperAnswerTime="paperAnswerTime" :showAnalysis="false" :id="id" :type="'test'" @jump="jump" @headerClick="headerClick"></examinationHeader>
      <chapterDetailCom ref="chapterDetailCom" :AnswerList="AnswerList" :isNext="isNext"
                        :fontType="fontType" @allEventFn="allEventFn" ></chapterDetailCom>
    </div>

    <div v-if="isCard">
      <answerCard @hideCard="hideCard" @headerClick="headerClick"></answerCard>
    </div>
  </div>
</template>

<script>

import {getChapterDetailList,pape_save_subject,test_paper_paper} from "@/api/chapter.js";
import chapterDetailCom from './chapterDetail.vue';
import examinationHeader from '../examinationHeader/examinationHeader.vue';
import answerCard from "@/pages/study/answerCard/answerCard";

export default {
  components: {
    chapterDetailCom,
    examinationHeader,
    answerCard
  },
  data() {
    return {
      AnswerList: [],
      isNext: false,
      isCard: false,
      id:'',
      paperAnswerTime:'',
      fontType: 'in'
    }
  },
  name: 'detail',
  created() {
    this.init();
  },
  methods: {
    init() {
      this.id = this.$route.query.id;
      let type = this.$route.query.typeBtn==='rest'?2:'';
      getChapterDetailList(this.$route.query.id, type).then((res) => {
        if(res.data.code == 0){
          this.AnswerList = res.data.data;
          this.paperAnswerTime = res.data.data.paperAnswerTime;
          this.$refs.chapterDetailCom.setSwiptTo(parseInt(this.AnswerList.completeNumber));
        }else{
          this.$dialog.alert({
            message: '抱歉，您还未购买该课程，暂时不能做题！',
          }).then(() => {
            this.$router.go(-1);
          });
        }
      })

    },
    hideCard(item) {
      this.isCard = false;
      if(item) {
        this.$refs.chapterDetailCom.setSwiptTo(parseInt(item.index));
      }
    },
    getAnswerTimeLength(times){
        let arys = times.split(":");
        let time1  = parseInt(arys[0])*60*60;
        let time2  = parseInt(arys[1])*60; 
        let time3  = parseInt(arys[2]);
        return time1+time2+time3; 
    },
    allEventFn(typeObj,res,index){
      if(typeObj.type=='radio'){
        let answerTimeLength = this.getAnswerTimeLength(this.$store.getters.getAnswerTime.times);
        pape_save_subject(answerTimeLength,this.$route.query.id,res.subjectId,res.answerId,"","").then((res)=>{});

      }else if(typeObj.type =='checkbox'){
        let answerTimeLength = this.getAnswerTimeLength(this.$store.getters.getAnswerTime.times);
        let answerIds = [];
        res.map((item)=>{ answerIds.push(item.answerId) });
        pape_save_subject(answerTimeLength,this.$route.query.id,res[0].subjectId,answerIds,"","").then((res)=>{})

      }else if(typeObj.type =='indeter'){
        let ansList = res[index];
        let answerTimeLength = this.getAnswerTimeLength(this.$store.getters.getAnswerTime.times);
        let answerIds = [];
        ansList.map((item)=>{ answerIds.push(item.answerId) });
        pape_save_subject(answerTimeLength,this.$route.query.id,ansList[0].subjectId,answerIds,"","").then((result)=>{})
      }else if(typeObj.type =='cal'){
        let ansList = res[index];
        let answerTimeLength = this.getAnswerTimeLength(this.$store.getters.getAnswerTime.times);
        pape_save_subject(answerTimeLength,this.$route.query.id,ansList.subjectId,"",ansList.doneAnswer,ansList.score).then((res)=>{});
      }
    },
    jump() {
      this.$router.push({
        path:"/answerReport",
        query:{
          id:this.$route.query.id,
          type: 'testPaper'
        }
      })
    },
    headerClick(item) {
      console.log(item);
      if (item.type === 'automaticNext') {
        // 自动下一题
        this.isNext = item.value;
      } else if (item.type === 'fontSize') {
        // 切换字体大小
        this.fontType = item.value;
      } else if (item.type === 'hand') {
        // 交卷
        test_paper_paper(this.$route.query.id).then((res)=>{
          if(res.data.code ==0){
            this.$refs.examinationHeader&&this.$refs.examinationHeader.hideHand();
            //答题报告
            if(!item.isJump) {
              this.$router.push({
                path:"/answerReport",
                query:{
                  id:this.$route.query.id,
                  type: 'testPaper'
                }
              })
            }

          }
        })
        
      } else if (item.type === 'answerCard') {
        // 答题卡
        this.isCard = true;
      }  else if (item.type === 'stop') {
        // 停止做题
        this.$refs.examinationHeader.hideHand();
      } else if (item.type === 'analysis') {
        // 答案解析

      }
    }

  }
}
</script>
<style lang="less" scoped>
.chapterDetail {
  width: 100%;
  background: #f5f5f5;
}
</style>
